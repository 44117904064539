<template>
    <div class="l-default l-auth t-signin">
        <Sprite />
        <svg
            class="l-default__background"
            viewBox="0 0 1920 682"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
            preserveAspectRatio="none"
        >
            <path
                d="M-12 -17H1933V573.652C1424.28 764.929 996.99 568.64 635.162 568.64C379.98 568.64 191.077 588.689 -12 681V-17Z"
                fill="#F3F6FF"
                stroke="#E2E6F8"
                stroke-linecap="round"
            />
        </svg>

        <main>
            <div class="l-auth__container -large">
                <a
                    href="https://semjuice.com"
                    class="a-button -transparent -small l-default__back text-nl-primary no-underline-hover"
                >
                    <svg class="a-svg" aria-hidden="true" focusable="false">
                        <use xlink:href="#icon-chevron-left"></use>
                    </svg>
                    <span>Retourner sur le site de Semjuice</span>
                </a>
                <div class="o-panel">
                    <aside class="o-panel__aside bg-nl-primary text-nl-white">
                        <div>
                            <svg class="a-svg -logo" aria-hidden="true" focusable="false">
                                <use xlink:href="#icon-nl-logo"></use>
                            </svg>
                            <h1 class="text-l o-panel__aside--title">
                                La plateforme d’achat de liens positionnés sur Google
                            </h1>
                            <p class="text-p text-italic">
                                Accélérez vos résultats SEO avec des backlinks immédiatement pris en compte par Google.
                            </p>
                        </div>
                        <div class="m-card -transparent" data-mobile="l-auth__container">
                            <p class="text-p">
                                Vous préférez être accompagné.e de façon personnalisée par nos experts SEO ?
                            </p>
                            <a href="#" class="a-button -link color-white-hover">
                                <svg class="a-svg -big" aria-hidden="true" focusable="false">
                                    <use xlink:href="#icon-calendar"></use>
                                </svg>
                                <span>Prendre rendez-vous</span>
                                <svg class="a-svg" aria-hidden="true" focusable="false">
                                    <use xlink:href="#icon-chevron-right"></use>
                                </svg>
                            </a>
                            <img src="@/assets/img-v2/rdv.svg" alt="" />
                        </div>
                    </aside>

                    <section class="o-panel__main">
                        <h2 class="text-l o-panel__main--title">Créer un compte sur NextLevel</h2>

                        <p class="text-pm text-nl-neutral-6">
                            Vous avez déjà un compte ?
                            <router-link to="/login" class="a-link no-underline-hover">Connectez-vous !</router-link>
                        </p>

                        <form class="o-panel__form" id="registration" ref="registerForm">
                            <fieldset class="m-form__fieldset">
                                <legend class="m-form__title text-xs text-nl-neutral-6">informations du compte</legend>
                                <div class="m-form__fields -col-2">
                                    <div>
                                        <label for="firstname" class="text-label"
                                            >Prénom <span class="required">*</span></label
                                        >

                                        <input
                                            name="firstname"
                                            id="firstname"
                                            v-model="user.firstname"
                                            class="a-input -user"
                                            type="text"
                                            required
                                            placeholder="Votre prénom"
                                        />
                                        <div class="invalid-feedback">
                                            {{ $t('register.error.mandatory') }}
                                        </div>
                                    </div>

                                    <div>
                                        <label for="lastname" class="text-label">
                                            Nom <span class="required">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="lastname"
                                            name="lastname"
                                            v-model="user.lastname"
                                            class="a-input -user"
                                            placeholder="Votre nom"
                                            required
                                        />

                                        <div class="invalid-feedback">
                                            {{ $t('register.error.mandatory') }}
                                        </div>
                                    </div>

                                    <div>
                                        <div class="phone-form-container">
                                            <label for="id_country_phone" class="text-label">
                                                Indicatif téléphonique <span class="required">*</span>
                                            </label>

                                            <vue-tel-input
                                                v-model="user.telephone"
                                                class="a-input -phone phone-form"
                                                :default-country="country[$i18n.locale]"
                                                :input-options="options"
                                                @validate="setValid"
                                            />
                                            <div v-show="validated && (!phone || !phone.valid)" class="errorPhone">
                                                <span v-if="user.telephone.length <= 0">{{
                                                    $t('register.error.mandatory')
                                                }}</span>
                                                <span v-else>{{ $t('register.error.phoneFormat') }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <label for="email" class="text-label"
                                            >E-mail <span class="required">*</span>
                                        </label>
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            v-model="user.email"
                                            class="a-input -email"
                                            placeholder="Indiquez votre adresse e-mail"
                                            required
                                        />
                                        <div class="invalid-feedback">
                                            {{ $t('register.error.mandatory') }}
                                        </div>
                                    </div>

                                    <div>
                                        <label for="password" class="text-label"
                                            >Mot de passe <span class="required">*</span></label
                                        >
                                        <input
                                            :type="passwordType"
                                            id="password"
                                            name="password"
                                            ref="password"
                                            v-model="user.password"
                                            class="a-input -password"
                                            :class="{ error: validationErrors.password.length > 0 }"
                                            placeholder="Choisissez un mot de passe"
                                            required
                                            @blur="onBlurPasswordValidation"
                                        />
                                        <div class="invalid-feedback">
                                            {{ $t('register.error.mandatory') }}
                                        </div>
                                        <div class="password__validation__errors">
                                            <p v-for="(error, index) in validationErrors.password" :key="index">
                                                {{ error.value }}
                                            </p>
                                        </div>
                                    </div>

                                    <div>
                                        <label for="password" class="text-label">
                                            Confirmation de mot de passe <span class="required">*</span>
                                        </label>
                                        <input
                                            :type="passwordConfirmType"
                                            id="password-confirm"
                                            ref="password_confirmation"
                                            v-model="user.password_confirmation"
                                            class="a-input -password"
                                            placeholder="Confirmez le mot de passe"
                                            required
                                            @blur="onBlurPasswordConfirmValidation"
                                        />
                                        <div class="invalid-feedback">
                                            {{ confirmPasswordMessage }}
                                        </div>
                                        <div class="password__validation__errors">
                                            <p v-for="(error, index) in validationErrors.passwordConfirm" :key="index">
                                                {{ error.value }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <div>
                                <b-field :message="cgvMessage" type="is-danger">
                                    <b-switch
                                        v-model="cgv"
                                        type="is-info"
                                        passive-type="is-secondary"
                                        name="cgv"
                                        required
                                    >
                                        {{ $t('register.accept') }}
                                        <a href="#" target="_blank">{{ $t('register.cgv') }}</a>
                                    </b-switch>
                                </b-field>
                            </div>

                            <fieldset class="m-form__fieldset">
                                <div class="m-form__fields">
                                    <div>
                                        <b-field>
                                            <b-switch
                                                v-model="isPartner"
                                                type="is-info"
                                                passive-type="is-secondary"
                                                name="isPartner"
                                            >
                                                {{ $t('register.partner') }}
                                            </b-switch>
                                        </b-field>
                                    </div>

                                    <fade-transition>
                                        <div v-show="isPartner" key="partnerDiv">
                                            <div class="m-form__fields -col-3">
                                                <div>
                                                    <label for="society_social_reason" class="text-label"
                                                        >Nom de la société <span class="required">*</span></label
                                                    >

                                                    <input
                                                        type="text"
                                                        id="society"
                                                        v-model="user.company"
                                                        class="a-input -society"
                                                        name="company"
                                                        placeholder="Votre entreprise"
                                                        :required="isPartner"
                                                    />
                                                    <div class="invalid-feedback">
                                                        {{ $t('register.error.society') }}
                                                    </div>
                                                </div>
                                                <div>
                                                    <label for="society_siret" class="text-label">
                                                        Numéro de SIRET ou SIREN <span class="required">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="siret"
                                                        v-model="user.siret"
                                                        class="a-input -society"
                                                        placeholder="362 521 879 00034"
                                                    />
                                                </div>
                                                <div>
                                                    <label for="society_tva" class="text-label">
                                                        Numéro de TVA <span class="required">*</span>
                                                    </label>

                                                    <input
                                                        type="text"
                                                        id="tva"
                                                        v-model="user.tva_number"
                                                        class="a-input -society"
                                                        placeholder="FR 32 123456789"
                                                    />
                                                </div>

                                                <div>
                                                    <label for="society_adress" class="text-label">
                                                        Adresse <span class="required">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="address"
                                                        v-model="user.address"
                                                        class="a-input -address"
                                                        placeholder="Indiquez une adresse"
                                                    />
                                                </div>

                                                <div>
                                                    <label for="society_cp" class="text-label"
                                                        >Code postal <span class="required">*</span></label
                                                    >

                                                    <input
                                                        type="text"
                                                        id="postalCode"
                                                        v-model="user.cp"
                                                        class="a-input -address"
                                                        placeholder="Entrez un code postal"
                                                    />
                                                </div>
                                                <div>
                                                    <label for="society_city" class="text-label">
                                                        Ville <span class="required">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="ville"
                                                        v-model="user.ville"
                                                        class="a-input -address"
                                                        placeholder="Entrez une ville"
                                                    />
                                                </div>
                                                <div class="form-country-container">
                                                    <nl-select
                                                        v-model="user.country"
                                                        class-label="text-label"
                                                        :name="$t('register.country')"
                                                        :label="$t('register.country')"
                                                        :items="countries"
                                                        :required="true"
                                                        prepend-dark
                                                    >
                                                        <template #prepend>
                                                            <i class="fas fa-mail-bulk" />
                                                        </template>
                                                    </nl-select>
                                                </div>
                                            </div>
                                            <div class="alert alert-danger mt-5 mb-5" role="alert">
                                                {{ $t('register.gains') }}
                                                <br />
                                                {{ $t('register.gainsWarning') }}
                                            </div>
                                            <div>
                                                <b-field>
                                                    <b-switch
                                                        v-model="user.accept_sms"
                                                        type="is-info"
                                                        passive-type="is-secondary"
                                                        name="alertSms"
                                                    >
                                                        {{ $t('register.alertSMS') }}
                                                    </b-switch>
                                                </b-field>
                                            </div>
                                            <div>
                                                <b-field :message="cgvPartnerMessage" type="is-danger">
                                                    <b-switch
                                                        v-model="cgvPartner"
                                                        type="is-info"
                                                        passive-type="is-secondary"
                                                        name="cgvPartner"
                                                    >
                                                        {{ $t('register.accept') }}
                                                        <a href="#" target="_blank">
                                                            {{ $t('register.cgvpartner') }}
                                                        </a>
                                                    </b-switch>
                                                </b-field>
                                            </div>
                                        </div>
                                    </fade-transition>
                                </div>
                            </fieldset>
                            <div class="m-form__footer">
                                <button
                                    class="a-button -primary"
                                    :disabled="loading"
                                    @click.prevent="validForm($event)"
                                >
                                    <span>Je valide mon inscription</span>
                                    <svg class="a-svg arrow" aria-hidden="true" focusable="false">
                                        <use xlink:href="#icon-arrow-right"></use>
                                    </svg>
                                </button>
                            </div>
                        </form>
                    </section>
                </div>
                <img class="l-auth__illu" src="@/assets/img-v2/nl-icon.svg" alt="" width="235" />
            </div>
        </main>
    </div>
</template>

<style lang="scss">
    .vti__dropdown {
        background-color: white;
    }

    .vti__dropdown :hover {
        background-color: white;
    }

    .errorPhone {
        margin-top: 0.25rem;
        font-size: 80%;
        color: #dc3545;
    }

    .label {
        font-weight: 600;
    }

    input.error {
        border-color: #dc3545;
    }

    .password__validation__errors {
        margin-bottom: 10px;

        p {
            margin: 0;
            font-size: 15px;
            color: #dc3545;
        }
    }
</style>

<script>
    import Sprite from '@/views/Svg/Sprite.vue'
    import Footer from '@/views/Public/Footer.vue'
    import LangSelector from '@/views/Public/LangSelector.vue'
    import Toaster from '@/components/UI/Toaster'
    import countryList from '@/utils/countries'
    import { mapActions } from 'vuex'
    import SelectCountry from '@/components/Form/SelectCountry.vue'

    export default {
        name: 'Register',
        components: {
            NlSelect: SelectCountry,
            Sprite,
            Footer,
            LangSelector,
            Toaster
        },
        title: 'register.register',
        data: function () {
            return {
                user: {
                    firstname: '',
                    lastname: '',
                    email: '',
                    telephone: '',
                    password: '',
                    password_confirmation: '',
                    company: '',
                    ville: '',
                    address: '',
                    cp: '',
                    country: '',
                    tva_number: '',
                    siret: '',
                    parrain: '',
                    accept_sms: false,
                    locale: this.$i18n.locale
                },

                validationErrors: {
                    password: [],
                    passwordConfirm: []
                },
                passwordType: 'password',
                passwordConfirmType: 'password',

                cgv: false,
                cgvPartner: false,
                isPartner: false,

                message: null,
                cgvMessage: null,
                cgvPartnerMessage: null,
                confirmPasswordMessage: null,
                telephoneMessage: '',
                validated: false,

                test_params: null,

                phone: null,
                options: {
                    placeholder: this.$t('register.phone'),
                    required: true,
                    id: 'phone'
                },

                country: {
                    fr: 'FR',
                    en: 'GB'
                },

                loading: false
            }
        },
        computed: {
            countries() {
                return countryList[this.$store.state.locale]
            }
        },
        created() {},
        methods: {
            ...mapActions('auth', ['login']),
            showMessage: function () {
                var dataDiv = document.getElementById('vue-data')

                if (dataDiv) {
                    this.message = JSON.parse(dataDiv.dataset.message)

                    this.$refs.toaster.fire('error', this.message.details, this.message.error)
                }
            },
            setValid: function (phoneObject) {
                if (phoneObject && phoneObject.valid === true) {
                    this.phone = phoneObject
                } else {
                    this.phone = null
                }
            },
            validForm: function (event) {
                var isValid = true
                var form = document.getElementById('registration')

                if (form.checkValidity() === false) {
                    isValid = false
                }

                if (!this.cgv) {
                    this.cgvMessage = this.$t('register.error.cgv')
                    isValid = false
                } else {
                    this.cgvMessage = null
                }

                if (this.isPartner === true && this.cgvPartner === false) {
                    this.cgvPartnerMessage = this.$t('register.error.cgv')
                    isValid = false
                } else {
                    this.cgvPartnerMessage = null
                }

                if (this.phone && this.phone.valid && this.phone.valid === true) {
                    this.telephoneMessage = ''
                } else {
                    isValid = false
                }

                if (
                    this.user.password.length > 0 &&
                    this.user.password_confirmation.length > 0 &&
                    this.password != this.password_confirmation
                ) {
                    this.confirmPasswordMessage = this.$t('register.error.passwordMismatch')
                    isValid = false
                } else if (this.user.password_confirmation.length <= 0) {
                    this.confirmPasswordMessage = this.$t('register.error.mandatory')
                    isValid = false
                } else {
                    this.confirmPasswordMessage = ''
                }

                if (this.validationErrors.password.length > 0 || this.validationErrors.passwordConfirm.length > 0) {
                    isValid = false
                }

                if (!isValid) {
                    event.preventDefault()
                    event.stopPropagation()
                }

                this.validated = true
                form.classList.add('was-validated')

                if (isValid) {
                    this.register()
                }
            },
            register: function () {
                this.loading = true

                var user = Object.assign({}, this.user)
                user.telephone = this.phone.number
                user.isPartner = this.isPartner
                user.user = this.user.email

                this.axios
                    .post('/user', user)
                    .then(() => {
                        // TODO: Putting back the google tag for conversion?
                        // this.$gtagevent('conversion', { send_to: 'AW-374683494/l8jTCIq-vo0CEObu1LIB' })
                        this.doLogin()
                    })
                    .catch(error => {
                        console.log(error)

                        if (error.response.status === 422) {
                            this.$store.dispatch('toastError', error.response.data)
                        } else {
                            this.$store.dispatch('toastError', error)
                        }

                        this.loading = false
                    })
            },
            doLogin() {
                this.loginin = true
                this.login({ email: this.user.email, password: this.user.password })
                    .then(() => {
                        this.$router.push(this.$store.state.homepage)
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
            },
            onLangChange(lang) {
                this.$i18n.locale = lang
            },
            onBlurPasswordValidation() {
                this.validationErrors.password = []

                if (this.user.password.length == 0) {
                    this.validationErrors.password.push({
                        key: 'empty',
                        value: this.$t('register.error.mandatory')
                    })
                    return
                }
                if (this.user.password.length < 6)
                    this.validationErrors.password.push({
                        key: 'lenght',
                        value: this.$t('register.error.lenght')
                    })
                if (!/[0-9]+/.test(this.user.password))
                    this.validationErrors.password.push({
                        key: 'number',
                        value: this.$t('register.error.number')
                    })
                if (!/[A-Z]+/.test(this.user.password))
                    this.validationErrors.password.push({
                        key: 'uppercase',
                        value: this.$t('register.error.uppercase')
                    })
                if (!/[a-z]+/.test(this.user.password))
                    this.validationErrors.password.push({
                        key: 'lowercase',
                        value: this.$t('register.error.lowercase')
                    })
                if (!/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(this.user.password))
                    this.validationErrors.password.push({
                        key: 'symbol',
                        value: this.$t('register.error.symbol')
                    })
            },
            onBlurPasswordConfirmValidation() {
                this.validationErrors.passwordConfirm = []

                if (this.user.password_confirmation.length == 0) {
                    this.validationErrors.passwordConfirm.push({
                        key: 'empty',
                        value: this.$t('register.error.mandatory')
                    })
                    return
                }
                if (this.user.password_confirmation != this.user.password)
                    this.validationErrors.passwordConfirm.push({
                        key: 'confirmation',
                        value: this.$t('register.error.mismatch')
                    })
            },
            onPasswordVisible() {
                this.passwordType = !this.passwordType
            },
            onPasswordConfirmVisible() {
                this.passwordConfirmType = !this.passwordConfirmType
            },
            moveCard() {
                const card = document.querySelector('[data-mobile]');
                const newParent = document.querySelector('.' + card?.dataset.mobile);
                const oldParent = document.querySelector('.o-panel__aside');

                if (!card || !newParent || !oldParent) return;

                if (window.innerWidth < 960) {
                    card.classList.add('-mobile');
                    newParent.appendChild(card);
                } else {
                    if (card.parentElement !== oldParent) {
                        card.classList.remove('-mobile');
                        oldParent.appendChild(card);
                    }
                }
            }
        },
        mounted() {
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = "/css/app.css";
            link.id = 'customCss';
            document.head.appendChild(link);

            this.moveCard();
            window.addEventListener('resize', this.moveCard);
        },
        beforeDestroy() {
            const link = document.getElementById('customCss');
            if (link) link.remove();
            window.removeEventListener('resize', this.moveCard); // Nettoie l'écouteur d'événements
        }
    }
</script>
